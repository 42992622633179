import Title from '@/components/Title/index.vue';
import Typo from '@/components/Typo/index.vue';
import Icon from '@/components/Icon/index.vue';
import Table from '@/components/Table/index.vue';
import TableColumn from '@/components/TableColumn/index.vue';
import Button from '@/components/Button/index.vue';
import Badge from '@/components/Badge/index.vue';
import Status from '@/components/Status/index.vue';
import Input from '@/components/Input/index.vue';
import Checkbox from '@/components/Checkbox/index.vue';
import ContextMenu from '@/components/ContextMenu/index.vue';
import DatePicker from '@/components/DatePicker/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import Layout from '@/components/Layout/index.vue';
import RadioButton from '@/components/RadioButton/index.vue';
import ValueCard from '@/components/ValueCard/index.vue';
import Tabs from '@/components/Tabs/index.vue';
import Modal from '@/components/Modal/index.vue';
import ButtonsGroup from '@/components/ButtonsGroup/index.vue';
import ItemSelector from '@/components/ItemSelector/index.vue';
import Accordion from '@/components/Accordion/index.vue';
import AutoComplete from '@/components/AutoComplete/index.vue';

export default {
  Title,
  Typo,
  Icon,
  Table,
  TableColumn,
  Button,
  Badge,
  Status,
  Input,
  Checkbox,
  ContextMenu,
  DatePicker,
  Pagination,
  Layout,
  RadioButton,
  ValueCard,
  Tabs,
  Modal,
  ButtonsGroup,
  ItemSelector,
  Accordion,
  AutoComplete,
};
