
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import DttIcon from '@/components/Icon/index.vue';

@Component({
  name: 'DttValueCard',
  components: { DttIcon, DttTypo },
})
export default class ValueCard extends Vue {
  @Prop({ type: String, default: 'md' }) size?: string;
  @Prop({ type: String, required: false }) header?: string;
  @Prop({ type: [String, Number], required: false }) value?: string | number;
  @Prop({ type: String }) icon?: string;
  @Prop({ type: String }) valueColor?: string;
  @Prop({ type: String }) iconColor?: string;
  @Prop({ type: String }) state?: keyof typeof this.stateMapping;

  stateMapping = {
    danger: {
      background: '#FFEDED',
      'border-color': '#FF573F',
    },
  };

  get stateStyles() {
    return this.state && this.stateMapping[this.state];
  }

  get iconStyles() {
    return {
      ...(!!this.iconColor && { background: this.iconColor }),
    };
  }
}
