
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import { getElementViewportArea, ViewportArea } from '@/utils/screen';
import DttIcon from '@/components/Icon/index.vue';
import DttCheckbox from '@/components/Checkbox/index.vue';
import DttTypo from '@/components/Typo/index.vue';
import {
  ContextMenuItem,
  ContextMenuSize,
} from '@/components/ContextMenu/types';

@Component({
  name: 'DttContextMenu',
  components: { DttTypo, DttCheckbox, DttIcon },
})
export default class ContextMenu extends Vue {
  @ModelSync('value', 'change', { default: null })
  modelValue!: ContextMenuItem | ContextMenuItem[] | any | any[] | null;
  @Prop({ type: Array, default: () => [] })
  options!: ContextMenuItem[];
  @Prop({ type: String, default: 'md' }) size!: ContextMenuSize;
  @Prop({ type: Boolean, default: false }) selectable!: boolean;
  @Prop({ type: Boolean, default: false }) splitted!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) useValue!: boolean;

  isOpen = false;
  area?: ViewportArea;

  get viewportAreaClass() {
    return `dtt-panel--${this.area}`;
  }

  get itemClasses() {
    return [this.splitted && 'splitted', this.size];
  }

  get iconSize() {
    switch (this.size) {
      case 'sm':
        return '18px';
      default:
        return '24px';
    }
  }

  toggle() {
    if (this.disabled) return;
    this.area = getElementViewportArea(this.$refs.target as Element);
    this.isOpen = !this.isOpen;
  }

  isActive(item: ContextMenuItem | any) {
    if (this.modelValue === null) return false;
    const checkingValue = this.useValue ? item.value : item;
    if (this.modelValue instanceof Array) {
      return !!this.modelValue.find((obj: ContextMenuItem | any) => {
        if (this.useValue) return obj === checkingValue;
        return obj.value === checkingValue.value;
      });
    }
    return this.modelValue === checkingValue;
  }

  onMenuClick(item: ContextMenuItem | any) {
    const checkingValue = this.useValue ? item.value : item;
    if (!this.selectable) {
      this.modelValue =
        this.modelValue === checkingValue ? null : checkingValue;
      this.isOpen = false;
      return;
    }

    if (this.modelValue instanceof Array) {
      const index = this.modelValue.findIndex((obj) => {
        if (this.useValue) return obj === checkingValue;
        return obj.value === checkingValue.value;
      });
      if (index === -1) {
        this.modelValue = [...this.modelValue, checkingValue];
        return;
      }
      const newModelValue = [
        ...this.modelValue.slice(0, index),
        ...this.modelValue.slice(index + 1),
      ];
      this.modelValue = newModelValue.length ? newModelValue : null;
    } else {
      this.modelValue = [checkingValue];
    }
  }

  outsideClickHandler(e: PointerEvent) {
    const el = this.$refs.el as Element;
    if (!el.contains(e.target as Node)) {
      this.isOpen = false;
    }
  }

  mounted() {
    //@ts-ignore
    document.addEventListener('click', this.outsideClickHandler);
  }

  beforeDestroy() {
    //@ts-ignore
    document.removeEventListener('click', this.outsideClickHandler);
  }
}
