
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconName } from '@/components/Icon/types';

const req = require.context('./../../icons/', true, /\.(vue)$/i);
const icons = req.keys().reduce((acc, key) => {
  const matches = key.match(/\w+/);
  if (matches) {
    const name = 'DttRawIcon' + matches[0];
    //@ts-ignore
    acc[name] = req(key).default;
  }
  return acc;
}, {});

@Component({
  name: 'DttIcon',
  components: { ...icons },
})
export default class Icon extends Vue {
  @Prop({ type: [Number, String], default: '24px' }) width!: number | string;
  @Prop({ type: [Number, String], default: '24px' }) height!: number | string;
  @Prop({ type: String, default: '0 0 24 24' }) viewBox!: string;
  @Prop({ type: String, required: true }) name?: IconName;
  @Prop({ type: String, default: '#59606A' }) color!: string;
  get componentName() {
    return `dtt-raw-icon-${this.name}`;
  }
  get wrapperStyles() {
    return {
      width: this.width,
      height: this.height,
      color: this.color,
    };
  }
}
