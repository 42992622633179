import type { PluginObject, VueConstructor } from 'vue';
import locale from '@/locale';
import * as components from './components';

interface Options {
  locale?: any;
  i18n?: any;
}

const componentsList = components.default;
const install = (vue: VueConstructor, opts?: Options) => {
  if (opts) {
    locale.use(opts.locale);
    locale.i18n(opts.i18n);
  }

  Object.values(componentsList).forEach((component) => {
    //@ts-ignore
    vue.component(component?.extendOptions?.name, component);
  });
};

export default {
  install,
  locale: locale.use,
  i18n: locale.i18n,
  ...componentsList,
} as PluginObject<unknown>;

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
